export default function TelephoneFormatUtil(value: string) {
    const cleaned = value.replace(/\D/g, '');
    let formattedPhoneNumber = '';

    if (cleaned.startsWith('7') || cleaned.startsWith('8')) {
        if (cleaned.length >= 1) {
            formattedPhoneNumber += `+${cleaned[0]}`;
        }
        if (cleaned.length > 1) {
            formattedPhoneNumber += ` (${cleaned.slice(1, 4)}`;
        }
        if (cleaned.length > 4) {
            formattedPhoneNumber += `) ${cleaned.slice(4, 7)}`;
        }
        if (cleaned.length > 7) {
            formattedPhoneNumber += ` ${cleaned.slice(7, 11)}`;
        }
        if (cleaned.length > 11) {
            formattedPhoneNumber += `${cleaned.slice(11)}`;
        }
    }
    else if (cleaned.startsWith('9')) {
        if (cleaned.length >= 1) {
            formattedPhoneNumber += `+${cleaned[0]}`;
        }
        if (cleaned.length > 1) {
            formattedPhoneNumber += `${cleaned.slice(1, 3)}`;
        }
        if (cleaned.length > 3) {
            formattedPhoneNumber += ` (${cleaned.slice(3, 6)}`;
        }
        if (cleaned.length > 6) {
            formattedPhoneNumber += `) ${cleaned.slice(6, 9)}`;
        }
        if (cleaned.length > 9) {
            formattedPhoneNumber += ` ${cleaned.slice(9, 12)}`;
        }
        if (cleaned.length > 12) {
            formattedPhoneNumber += `${cleaned.slice(12)}`;
        }
    }
    else {
        if (cleaned.length >= 1) {
            formattedPhoneNumber += `+${cleaned[0]}`;
        }
        if (cleaned.length > 1) {
            formattedPhoneNumber += ` ${cleaned.slice(1)}`;
        }
    }

    return formattedPhoneNumber;
}