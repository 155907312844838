import { styled } from "@mui/material";

const RetirementComponent = ({ size = 20 }: { size?: number }) => {
    const Retirement = styled('div')({
        height: size,
        width: size,
    });

    return <Retirement />
}

export default RetirementComponent;