import { action, makeObservable, observable } from 'mobx';
import { KitsResponse } from '../model/KitsResponse';

class KitsDataStore {
    kitsData: KitsResponse | null = null;

    constructor() {
        makeObservable(this, {
            kitsData: observable,
            saveData: action,
        });
    }

    saveData(value: KitsResponse) {
        this.kitsData = value;
    }
}

const kitsDataStore = new KitsDataStore();
export default kitsDataStore;
