import { styled } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";

const TextComponent = ({ children, uppercase = false, multiline = false, lines = 3, type = "medium", color = TemplateConfig.blackColor, weight }: { children: any; uppercase?: boolean; multiline?: boolean; lines?: number; type?: string; color?: string; weight?: number }) => {
    // init
    let size = 0;
    let localWeight = 0;
    let localColor = color;

    // get style
    switch (type) {
        case 'extraLarge':
            size = TemplateConfig.extraLargeFontSize;
            localWeight = TemplateConfig.heavyText;
            break;
        case 'large':
            size = TemplateConfig.largeFontSize;
            localWeight = TemplateConfig.semiboldText;
            break;
        case 'boldMedium':
            size = TemplateConfig.mediumFontSize;
            localWeight = TemplateConfig.semiboldText;
            break;
        default:
            size = TemplateConfig.mediumFontSize;
            localWeight = TemplateConfig.mediumText;
            break;
        case 'medium':
            size = TemplateConfig.mediumFontSize;
            localWeight = TemplateConfig.regularText;
            break;
        case 'small':
            size = TemplateConfig.smallFontSize;
            localWeight = TemplateConfig.regularText;
            break;
        case 'extraSmall':
            size = TemplateConfig.extraSmallFontSize;
            localWeight = TemplateConfig.lightweightText;
            break;
    }

    // init div
    const TextBlockWithRowLimit = styled('div')({
        display: "-webkit-box",
        lineClamp: lines,
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        fontSize: size,
        fontWeight: weight ?? localWeight,
        color: localColor,
        textTransform: uppercase ? "uppercase" : "none",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textDecoration: 'none'
    });
    const DefaultTextBlock = styled('div')({
        fontSize: size,
        fontWeight: weight ?? localWeight,
        color: localColor,
        textTransform: uppercase ? "uppercase" : "none",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        textDecoration: 'none',
    });

    // widget
    return (
        multiline
            ? <TextBlockWithRowLimit>{children}</TextBlockWithRowLimit>
            : <DefaultTextBlock>{children}</DefaultTextBlock>
    );
}

export default TextComponent;