import { action, makeObservable, observable } from 'mobx';
import { HistoryDataResponse, Procedure } from '../model/HistoryDataResponse';

class HistoryDataStore {
    historyData: HistoryDataResponse | null = null;
    historyCurrentPage: number = 1;
    historyLoadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            historyData: observable,
            historyCurrentPage: observable,
            pushHistory: action,
            removeHistory: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            loadDisable: action
        });
    }

    incrementCurrentPage() {
        return this.historyCurrentPage = this.historyCurrentPage + 1;
    }

    resetCurrentPage() {
        return this.historyCurrentPage = 1;
    }

    loadDisable(value: boolean) {
        this.historyLoadAviable = value;
    }

    pushHistory(procedures: Procedure[]) {
        if (!this.historyData) {
            this.historyData = {
                procedures: procedures
            }
        } else {
            this.historyData.procedures = [...this.historyData.procedures, ...procedures];
        }
    }

    removeHistory() {
        this.historyData = null;
    }
}

const historyDataStore = new HistoryDataStore();
export default historyDataStore;
