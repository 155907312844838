import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { SharedCardItemModel } from "../../shared/model/SharedCardItemModel";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { observer } from "mobx-react-lite";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import SearchComponent from "./SearchComponent";
import partnersDataStore from "../store/PartnersDataStore";
import GetPartnersService from "../service/GetPartnersService";
import UpdateDataService from "../service/UpdateDataService";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RankNameUtil from "../../../core/utils/RankNameUtil";
import DataComponent from "../../authenticate/ui/DataComponent";
import { Partners } from "../model/PartnersResponse";

const PartnersComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!partnersDataStore.partnersData && open && partnersDataStore.partnersLoadAviable) { await GetPartnersService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // search
    const [searchWindow, setSearchWindow] = useState(false);

    // edit
    const [editWindow, setEditWindow] = useState(false);
    const [editPartner, setEditPartner] = useState<Partners | null>(null);
    const [editIndex, setEditIndex] = useState(0);

    // partners 
    const Partners = () => {
        if (!partnersDataStore.partnersData) return null;

        const partnerItems: SharedCardItemModel[] = [];
        partnersDataStore.partnersData.partners.map((field, index) => (
            partnerItems.push({
                label: TelephoneFormatUtil(field.telephone),
                text: `ЛК: ${PriceFormatUtil(Number(field.left_amount), false, 'PV')} ПК: ${PriceFormatUtil(Number(field.right_amount), false, 'PV')}, ${RankNameUtil(field.left_total_amount, field.right_total_amount)}`,
                subtitle: `${field.username ?? 'Не указано'}, ${field.surname ?? 'Не указано'}, ${field.address ?? 'Не указано'}`,
                value: "",
                valueComponent: <TextButtonComponent
                    placeholder="Ред."
                    onClick={() => {
                        setEditPartner(field);
                        setEditIndex(index);
                        setEditWindow(true);
                    }}
                />,
                last: index === (partnersDataStore.partnersData?.partners.length! - 1),
            })
        ))
        return (
            <SharedCardItemComponent>
                {partnerItems}
            </SharedCardItemComponent>
        );
    }
    // function
    async function saveData(username: string, surname: string, address: string, telephone: string) {
        await UpdateDataService({
            username: username,
            surname: surname,
            address: address,
            telephone: telephone,
            index: editIndex,
            pointCustomerId: editPartner!.customer_id
        })
        setReload(!reload);
    }
    async function loadMore() {
        var newPage = partnersDataStore.incrementCurrentPage();
        await GetPartnersService({ page: newPage });
        setReload(!reload);
    }
    async function reset() {
        partnersDataStore.loadDisable(true);
        partnersDataStore.resetCurrentPage();
        await GetPartnersService({ page: 1, reset: true });
        setReload(!reload);
    }
    async function search(telephone: string) {
        partnersDataStore.resetCurrentPage();
        await GetPartnersService({ page: 1, telephone: telephone.replace(/\D/g, '') });
        setReload(!reload);
    }

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Партнеры'>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <TextButtonComponent
                        placeholder="Поиск"
                        onClick={() => setSearchWindow(true)}
                    />
                    <RetirementComponent size={10} />
                    <TextButtonComponent
                        placeholder="Сбросить"
                        onClick={reset}
                    />
                </div>
                <RetirementComponent size={20} />
                <Partners />
                {
                    partnersDataStore.partnersLoadAviable
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Загрузить еще"
                            />
                        </div>
                        : null
                }
            </StandartBottomSheetComponent>
            <SearchComponent open={searchWindow} onDismiss={() => setSearchWindow(false)} onSubmit={search} />
            <DataComponent open={editWindow} onDismiss={() => setEditWindow(false)} onSubmit={saveData} init={editPartner} admin />
        </>
    );
}

export default observer(PartnersComponent);