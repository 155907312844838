import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import partnerDataStore from "../store/PartnerDataStore";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { useState } from "react";
import TreeComponent from "./TreeComponent";
import RankNameUtil from "../../../core/utils/RankNameUtil";
import ReferralsComponent from "./ReferralsComponent";
import { observer } from "mobx-react-lite";
import PackageNameUtil from "../../../core/utils/PackageNameUtil";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";

const PartnerComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // tree
    const [tree, setTree] = useState(false);

    // team
    const [team, setTeam] = useState(false);

    return (
        <>
            {
                <div>
                    <SharedCardItemComponent
                        buttonText="Команда"
                        buttonAction={() => setTeam(true)}
                    >
                        {
                            [
                                { label: "Ранг", value: RankNameUtil(partner.left_total_amount, partner.right_total_amount) === 'No Rank' ? '—' : RankNameUtil(partner.left_total_amount, partner.right_total_amount) },
                                { label: "Кейс", value: partner.personal_amount === '0' ? 'Нет' : PackageNameUtil(partner.personal_amount) },
                                { label: "Личная продажа", value: `${partner.personal_amount} PV` },
                                { label: "Левая команда (Ранг)", value: PriceFormatUtil(Number(partner.left_total_amount), false, 'PV') },
                                { label: "Правая команда (Ранг)", value: PriceFormatUtil(Number(partner.right_total_amount), false, 'PV') },
                                { label: "Спонсор", value: partner.referrer_telephone! ? TelephoneFormatUtil(partner.referrer_telephone!) : 'Нет' },
                                { label: "Участник фонда", value: partner.fund_permission === '1' ? 'Да' : 'Нет' },
                                { label: "Личная команда", value: partner.invited_size },
                            ]
                        }
                    </SharedCardItemComponent>
                    {
                        partner.left_referral_link
                            ? <>
                                <RetirementComponent />
                                <SharedCardItemComponent
                                    buttonText="Бинар"
                                    buttonAction={
                                        () => setTree(true)
                                    }
                                >
                                    {
                                        [
                                            { label: "Левая команда", value: PriceFormatUtil(Number(partner.left_amount), false, 'PV') },
                                            { label: "Правая команда", value: PriceFormatUtil(Number(partner.right_amount), false, 'PV') },
                                            {
                                                label: "Реферальная ссылка", value: "Левая", clickable: true, onClick: () => CopyTextUtil({
                                                    text: `${ApplicationConfig.applicationLink}/?referralLink=${partner.left_referral_link}`
                                                })
                                            },
                                            {
                                                label: "Реферальная ссылка", value: "Правая", clickable: true, onClick: () => CopyTextUtil({
                                                    text: `${ApplicationConfig.applicationLink}/?referralLink=${partner.right_referral_link}`
                                                })
                                            }
                                        ]
                                    }
                                </SharedCardItemComponent>
                            </>
                            : null
                    }
                </div>
            }
            <TreeComponent open={tree} onDismiss={() => setTree(false)} />
            <ReferralsComponent open={team} onDismiss={() => setTeam(false)} />
        </>
    );
}

export default observer(PartnerComponent);