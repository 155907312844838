import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import AuthorizationService from "../service/AuthorizationService";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import GetLinkData from "../../partner/service/GetLinkData";
import { LinkDataResponse } from "../../partner/model/LinkDataResponse";
import FullIcon from '../../../core/assets/module/shared/avo.png';
import UrlLauncherUtil from "../../../core/utils/UrlLauncherUtil";
import TelephoneFormateUtil from "../../../core/utils/TelephoneFormateUtil";
import { toast } from "react-toastify";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import InputComponent from "../../shared/ui/InputComponent";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import TextComponent from "../../shared/ui/TextComponent";
import EyeIcon from "../../../core/assets/module/input/eye.svg";
import EyeShowIcon from "../../../core/assets/module/input/eye-show.svg";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RouteNamespaces from "../../../core/route/RouteNamespaces";

const AuthenticateComponent = () => {
  async function initAuthorize() {
    if (telephoneField.current.length < 10 || (codeSended && codeField.current.length !== 4)) {
      toast("Введите корректные данные", { type: "error" });
      return;
    }

    if (isPassword && password.current.length < 3) {
      toast("Неверный пароль", { type: "error" });
      return;
    }

    var result = await AuthorizationService({
      telephone: telephoneField.current.replace(/\D/g, ''),
      referralLink: searchParams.get('referralLink'),
      navigate: navigate,
      code: codeSended ? codeField.current : undefined,
      password: isPassword ? password.current : undefined
    });
    if (result === "password") {
      setIsPassword(true);
      return;
    }
    if (!codeSended && result) { codeSendedChange(true); setTime(60); }
  }

  async function resendCode() {
    if (codeSended && time > 0) {
      toast("Дождитесь таймера", { type: "error" });
      return;
    }

    var result = await AuthorizationService({
      telephone: telephoneField.current.replace(/\D/g, ''),
      referralLink: searchParams.get('referralLink'),
      navigate: navigate
    });

    if (result) setTime(60);
  }

  // telephone
  const telephoneField = useRef('');
  const onTelephoneChange = (newValue: string) => { telephoneField.current = newValue };

  // code
  const codeField = useRef('');
  const onCodeChange = (newValue: string) => { codeField.current = newValue };
  const [codeSended, codeSendedChange] = useState(false);

  // password
  const [isPassword, setIsPassword] = useState(false);
  const password = useRef('');
  const onPasswordChange = (v: string) => { password.current = v; };
  const [obscure, setObscure] = useState(true);

  // timer
  const [time, setTime] = useState(0);
  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [time]);

  // partner referral
  const [searchParams] = useSearchParams();
  const referralLink = searchParams.get('referralLink');
  const [referralEmail, setReferralEmail] = useState('');
  useEffect(() => {
    async function getReferralNumber() {
      const result = await GetLinkData({ referralLink: referralLink! });
      if (result) setReferralEmail(TelephoneFormateUtil((result.data as LinkDataResponse).referrer.telephone));
      return;
    }

    if (referralLink && referralLink.length > 0) getReferralNumber();
  }, [referralLink]);

  // navigate
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
      <img src={FullIcon} alt="project icon" height={150} />
      <RetirementComponent size={30} />
      {
        referralEmail.length < 1
          ? null
          : <div>
            <InputComponent
              disabled={true}
              initText={`Спонсор: ${referralEmail}`}
              placeholder=""
              handleTextFieldValueChange={null}
            />
            <RetirementComponent />
          </div>
      }
      <InputComponent
        placeholder="Номер телефона"
        handleTextFieldValueChange={onTelephoneChange}
        mobileFilter
      />
      <RetirementComponent size={10} />
      {
        isPassword && <>
          <InputComponent
            initText={password.current}
            placeholder="Пароль"
            handleTextFieldValueChange={onPasswordChange}
            maxLength={100}
            password={obscure}
            endAdornment={<LinkWrapperComponent onClick={() => setObscure(!obscure)}>
              <img src={obscure ? EyeIcon : EyeShowIcon} alt="obscure icon" />
            </LinkWrapperComponent>}
          />
          <RetirementComponent size={10} />
        </>
      }
      {
        codeSended
          ? <>
            <InputComponent
              placeholder="Код подтверждения"
              handleTextFieldValueChange={onCodeChange}
              filter={/[^0-9]/g}
              maxLength={4}
            />
            <RetirementComponent size={10} />
          </>
          : null
      }
      {
        codeSended
          ? <>
            <TextButtonComponent
              onClick={() => resendCode()}
              placeholder={`Отправить еще раз: ${time} сек.`}
            />
            <RetirementComponent size={10} />
          </>
          : null
      }
      <RaisedButtonComponent
        placeholder="Продолжить"
        onClick={() => initAuthorize()}
      />
      <RetirementComponent />
      <div style={{ margin: '0 auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        <TextComponent> Продолжая, вы соглашаетесь с&nbsp;</TextComponent>
        <TextButtonComponent onClick={() => UrlLauncherUtil({ url: `https://avoshop.kz/agreement.html`, newTab: true })} placeholder="условиями оферты" />
        <TextComponent>&nbsp;и&nbsp;</TextComponent>
        <TextButtonComponent onClick={() => UrlLauncherUtil({ url: `https://avoshop.kz/privacy.html`, newTab: true })} placeholder="политикой конфиденциальности" />
        <TextComponent>.</TextComponent>
      </div>
      {
        isPassword && <div>
          <RetirementComponent size={10} />
          <TextButtonComponent
            onClick={() => navigate(RouteNamespaces.recovery)}
            placeholder="Забыли пароль?"
          />
        </div>
      }
    </div>
  );
}

export default AuthenticateComponent;