import rankDataStore from "../../module/partner/store/RankStore";

export default function RankNameUtil(left_total_amount: string, right_total_amount: string) {
    if (!rankDataStore.rankData) return "Не найдено";

    const leftTotalAmount = Number(left_total_amount);
    const rightTotalAmount = Number(right_total_amount);
    const minimalTotalAmount = Math.min(leftTotalAmount, rightTotalAmount);

    let foundRankIndex = 0;
    for (let index = 0; index < rankDataStore.rankData.ranks.length; index++) {
        const item = rankDataStore.rankData.ranks[index];
        if (Number(item.border) > minimalTotalAmount) break;
        foundRankIndex = index;
    }

    return rankDataStore.rankData.ranks[foundRankIndex].name;
}
