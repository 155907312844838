import { useRef } from "react";
import { toast } from "react-toastify";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import TransferService from "../service/TransferService";

const TransferComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // amount
    const amountFieldRef = useRef('');
    const onAmountFieldRefChange = (newValue: string) => { amountFieldRef.current = newValue };

    // telephone
    const telephoneFieldRef = useRef('');
    const onTelephoneFieldRefChange = (newValue: string) => { telephoneFieldRef.current = newValue; };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Перевод средств'>
            <InputComponent
                placeholder="Сумма для перевода"
                handleTextFieldValueChange={onAmountFieldRefChange}
                filter={/[^0-9]/g}
                maxLength={11}
                priceFilter
            />
            <RetirementComponent size={10} />
            <InputComponent
                placeholder="Номер телефона получателя"
                handleTextFieldValueChange={onTelephoneFieldRefChange}
                mobileFilter
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder="Продолжить"
                onClick={() => {
                    if (amountFieldRef.current.length < 1) {
                        toast("Введите корректную сумму", { type: "error" });
                        return;
                    }

                    if (telephoneFieldRef.current.replace(/\D/g, '') === customerDataStore.customerData?.customer.telephone || telephoneFieldRef.current.length < 15) {
                        toast("Введите корректный номер телефона", { type: "error" });
                        return;
                    }

                    if (!partnerDataStore.partnerData || Number(amountFieldRef.current.replace(/\s/g, '')) > Number(partnerDataStore.partnerData.partner.balance)) {
                        toast("Недостаточно баланса", { type: "error" });
                        return;
                    }

                    TransferService({ amount: Number(amountFieldRef.current.replace(/\D/g, '')), telephone: telephoneFieldRef.current.replace(/\D/g, '') });
                    onDismiss();
                }}
            />
        </StandartBottomSheetComponent>
    );
}

export default TransferComponent;