export default class RouteNamespaces {
    static authenticate: string = '/';

    static profile: string = '/profile';
    static profileName: string = 'Аккаунт';

    static market: string = '/market';
    static marketName: string = 'Кейсы';

    static partner: string = '/partner';
    static partnerName: string = 'Сеть';

    static balance: string = '/balance';
    static balanceName: string = 'Счета';

    static admin: string = '/admin';
    static adminName: string = 'Админ';

    static logoutName: string = 'Выход';

    static recovery: string = '/recovery';
    static error: string = '/error';
    static another: string = '*';
}