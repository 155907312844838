import { action, makeObservable, observable } from 'mobx';
import { PartnerDataResponse } from '../model/PartnerDataResponse';
import { ShortPartnerDataResponse } from '../model/ShortPartnerDataResponse';

class PartnerDataStore {
    partnerData: PartnerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            partnerData: observable,
            savePartner: action,
            removePartner: action,
            reduceBalance: action,
            resetCashback: action,
            replaceShortData: action
        });
    }

    savePartner(value: PartnerDataResponse) {
        this.partnerData = value;
    }

    reduceBalance(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance = (Number(this.partnerData.partner.balance) - value).toString();
    }

    resetCashback() {
        if (!this.partnerData) return;
        this.partnerData.partner.cashback_amount = '0';
    }

    replaceShortData(value: ShortPartnerDataResponse) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance = value.partner.balance;
        this.partnerData.partner.personal_amount = value.partner.personal_amount;
        this.partnerData.partner.left_referral_link = value.partner.left_referral_link;
        this.partnerData.partner.right_referral_link = value.partner.right_referral_link;
        this.partnerData.partner.cashback_amount = value.partner.cashback_amount;
    }

    removePartner() {
        this.partnerData = null;
    }
}

const partnerDataStore = new PartnerDataStore();
export default partnerDataStore;
