import { Button } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TextComponent from "./TextComponent";

const RaisedButtonComponent = ({ onClick, placeholder, fullWidth = true, backgroundColor = TemplateConfig.greenColor, icon, textColor = TemplateConfig.whiteColor, padding, textType }: { onClick: any; placeholder: string; fullWidth?: boolean; backgroundColor?: any; icon?: any; textColor?: string; padding?: string; textType?: string }) => {
    return (
        <Button
            style={{
                textTransform: 'none',
                borderRadius: TemplateConfig.extraBorderRadius,
                padding: padding ?? '10px 25px 10px 25px',
                width: fullWidth ? '100%' : undefined,
                backgroundColor: backgroundColor
            }}
            onClick={onClick}
        >
            <div style={{ display: 'flex' }}>
                <TextComponent color={textColor} type={textType}>
                    {placeholder}
                </TextComponent>
                {
                    icon
                        ? <img style={{ paddingLeft: 5 }} src={icon} alt="custom icon" />
                        : null
                }
            </div>
        </Button>
    );
}

export default RaisedButtonComponent;