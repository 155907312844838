import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import ordersDataStore from "../store/OrdersDataStore";
export default async function DeliveryService({ orderId, status, index }: { orderId: string; status: string; index: number }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "delivery",
        "accessToken": accessToken,
        "orderId": orderId,
        "status": status
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    ordersDataStore.deliveredStatusChange(index, status);
}