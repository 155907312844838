import ApplicationConfig from "../../../core/config/ApplicationConfig";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const AddressComponent = ({ open, onDismiss, onSubmit }: { open: boolean; onDismiss: any; onSubmit: any }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Город">
            <SharedListComponent items={
                ApplicationConfig.city.map((field) => {
                    return { label: field, onClick: () => onSubmit(field), icon: null }
                })
            }
            />
        </StandartBottomSheetComponent>
    );
}

export default AddressComponent;