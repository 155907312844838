import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function CashbackService({ telephone }: { telephone: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "balance",
        "process": "cashback",
        "accessToken": accessToken,
        "telephone": telephone
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: "Аккаунт с таким номером телефона не найден"
        }
    );

    // check result
    if (!result || !result.data) return;

    // store edit
    if (result.status === 'succes') {
        partnerDataStore.resetCashback();
        toast("Успешно переведено", { type: "success" });
    }
}