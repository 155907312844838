export default function PriceFormatUtil(value: number, isFloat: boolean = false, symbol = '$') {
    const formatter = new Intl.NumberFormat('kz-KZ', {
        style: 'currency',
        currency: 'KZT',
        currencyDisplay: 'symbol',
        minimumFractionDigits: isFloat ? 2 : 0,
        maximumFractionDigits: isFloat ? 2 : 0,
    });

    const formattedValue = formatter.format(value);
    const replacedValue = formattedValue.replace(/KZT/g, symbol);

    return replacedValue;
}