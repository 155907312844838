import { action, makeObservable, observable } from 'mobx';
import { RanksResponse } from '../model/RanksResponse';

class RankStore {
    rankData: RanksResponse | null = null;

    constructor() {
        makeObservable(this, {
            rankData: observable,
            saveData: action
        });
    }

    saveData(value: RanksResponse) {
        this.rankData = value;
    }
}

const rankDataStore = new RankStore();
export default rankDataStore;
