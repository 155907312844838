import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import { SharedCardItemModel } from "../../shared/model/SharedCardItemModel";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import referralDataStore from "../store/ReferralDataStore";
import GetReferralsService from "../service/GetReferralsService";
import { observer } from "mobx-react-lite";
import RankNameUtil from "../../../core/utils/RankNameUtil";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";

const ReferralsComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!referralDataStore.referralData && open && referralDataStore.referralLoadAviable) { await GetReferralsService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // history 
    const History = () => {
        if (!referralDataStore.referralData) return null;

        const referralItems: SharedCardItemModel[] = [];
        referralDataStore.referralData.referrals.map((field, index) => (
            referralItems.push({
                label: TelephoneFormatUtil(field.telephone),
                subtitle: `ЛК: ${PriceFormatUtil(Number(field.left_amount), false, 'PV')} ПК: ${PriceFormatUtil(Number(field.right_amount), false, 'PV')}`,
                text: RankNameUtil(field.left_total_amount, field.right_total_amount),
                value: PriceFormatUtil(Number(field.personal_amount), false, 'PV'),
                last: index === (referralDataStore.referralData?.referrals.length! - 1),
            })
        ))
        return (
            <SharedCardItemComponent>
                {referralItems}
            </SharedCardItemComponent>
        );
    }
    async function loadMore() {
        var newPage = referralDataStore.incrementCurrentPage();
        await GetReferralsService({ page: newPage });
        setReload(!reload);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Команда'>
            <History />
            {
                referralDataStore.referralLoadAviable
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            onClick={loadMore}
                            placeholder="Загрузить еще"
                        />
                    </div>
                    : null
            }
        </StandartBottomSheetComponent>
    );
}

export default observer(ReferralsComponent);