import ApplicationConfig from "../../../core/config/ApplicationConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { AuthorizeResponse } from "../model/AuthorizeResponse";
import CustomerDataService from "./CustomerDataService";

export default async function AuthorizationService({ telephone, referralLink, code, password, navigate }: { telephone: string; referralLink?: string | null; code?: string; password?: string; navigate: any }) {
    const params = {
        "module": "account",
        "process": "auth",
        "telephone": telephone,
        "referralLink": referralLink ?? '',
        "code": code ?? '',
        "password": password ?? ''
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: password ? "Неверный пароль" : undefined
        }
    );
    if (!result || !result.data) return false;
    if (result.data.message && result.data.message === 'password') {
        return "password";
    }
    if ((code || password) && result.data.access_token) {
        var accessToken = (result.data as AuthorizeResponse).access_token;
        localStorage.setItem('accessToken', accessToken);
        await CustomerDataService(accessToken);
        navigate(RouteNamespaces.profile);
        return true;
    }

    return true;
}