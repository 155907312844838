import { useState } from "react";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import variablesStore from "../store/VariablesStore";
import FundComponent from "./FundComponent";
import FundPartnersComponent from "./FundPartnersComponent";
import OrdersComponent from "./OrdersComponent";
import PartnersComponent from "./PartnersComponent";

const AdminComponent = () => {
    // init
    const variables = variablesStore.variables?.variables;
    const [fund, setFund] = useState(false);
    const [orders, setOrders] = useState(false);
    const [partners, setPartners] = useState(false);
    const [fundPartners, setFundPartners] = useState(false);

    return (
        <>
            <SharedCardItemComponent>
                {
                    [
                        { label: "Доход", value: PriceFormatUtil(Number(variables?.profit_amount), true) },
                        { label: "Расход", value: PriceFormatUtil(Number(variables?.expense_amount), true) },
                        { label: "Прибыль", value: PriceFormatUtil(Number(variables?.profit_amount) - Number(variables?.expense_amount), true) },
                        { label: "Фонд", value: "Раздать", onClick: () => setFund(true), clickable: true },
                        { label: "Заказы", value: "Посмотреть", onClick: () => setOrders(true), clickable: true },
                        { label: "Партнеры", value: "Посмотреть", onClick: () => setPartners(true), clickable: true },
                        { label: "Участники фонда", value: "Посмотреть", onClick: () => setFundPartners(true), clickable: true, last: true },
                    ]
                }
            </SharedCardItemComponent>
            <FundComponent open={fund} onDismiss={() => setFund(false)} />
            <OrdersComponent open={orders} onDismiss={() => setOrders(false)} />
            <PartnersComponent open={partners} onDismiss={() => setPartners(false)} />
            <FundPartnersComponent open={fundPartners} onDismiss={() => setFundPartners(false)} />
        </>
    );
}

export default AdminComponent;