export default class TemplateConfig {
    // size
    static mobileMaxWidth: number = 520;
    static isMobile = window.innerWidth <= TemplateConfig.mobileMaxWidth;

    // radius
    static miniBorderRadius: number = 8;
    static borderRadius: number = 16;
    static extraBorderRadius: number = 48;

    // padding
    static miniPadding: number = 8;
    static defaultPadding: number = 16;
    static extraPadding: number = 24;

    // font size
    static extraSmallFontSize: number = 12;
    static smallFontSize: number = 14;
    static mediumFontSize: number = 16;
    static largeFontSize: number = 20;
    static extraLargeFontSize: number = 24;

    // weight
    static lightweightText: number = 200;
    static regularText: number = 300;
    static mediumText: number = 400;
    static semiboldText: number = 500;
    static heavyText: number = 700;

    // color
    static whiteColor: string = '#ffffff';
    static blackColor: string = '#000000';
    static blackColorOpacity: string = 'rgb(0, 0, 0, 0.25)';
    static greyColor: string = '#F1F4F8';
    static greyColorOpacity: string = 'rgb(241, 244, 248, 0.5)';
    static yellowColor: string = '#a8cf45';
    static greenColor: string = '#185634';
}