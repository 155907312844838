import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import historyDataStore from "../store/HistoryDataStore";
import GetHistoryService from "../service/GetHistoryService";
import { SharedCardItemModel } from "../../shared/model/SharedCardItemModel";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";

const HistoryComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!historyDataStore.historyData && open && historyDataStore.historyLoadAviable) { await GetHistoryService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // history 
    const History = () => {
        if (!historyDataStore.historyData) return null;

        const historyItems: SharedCardItemModel[] = [];
        historyDataStore.historyData.procedures.map((field, index) => (
            historyItems.push({
                label: field.text,
                subtitle: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                value: PriceFormatUtil(Number(field.amount), true),
                // icon: <img style={{ paddingRight: 15 }} src={field.module === 'market' ? MarketIcon : PartnerIcon} alt="history icon" />,
                last: index === (historyDataStore.historyData?.procedures.length! - 1),
            })
        ))
        return (
            <SharedCardItemComponent>
                {historyItems}
            </SharedCardItemComponent>
        );
    }
    async function loadMore() {
        var newPage = historyDataStore.incrementCurrentPage();
        await GetHistoryService({ page: newPage });
        setReload(!reload);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='История транзакции'>
            <History />
            {
                historyDataStore.historyLoadAviable
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            onClick={loadMore}
                            placeholder="Загрузить еще"
                        />
                    </div>
                    : null
            }
        </StandartBottomSheetComponent>
    );
}

export default HistoryComponent;