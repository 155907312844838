import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Tree, TreeNode } from 'react-organizational-chart';
import TemplateConfig from "../../../core/config/TemplateConfig";
import TextComponent from "../../shared/ui/TextComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import ArrowUpIcon from '../../../core/assets/module/shared/arrow-up.svg';
import ArrowDownIcon from '../../../core/assets/module/shared/arrow-down.svg';
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import treeDataStore from "../store/TreeDataStore";
import { useEffect, useState } from "react";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import GetTreeData from "../service/GetTreeData";

const TreeComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    useEffect(() => {
        async function fetch() { if (!treeDataStore.treeData && open) { await GetTreeData({ init: true }); setCurrentTreeData(treeDataStore.treeData); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);


    const treeItem = ({ id, username, left_amount, right_amount, personal_amount, isNull = false, isLastRow = false, isUpAvailable = false }: { id?: string; username?: string | null; left_amount?: string | null; right_amount?: string | null; personal_amount?: string | null; isNull?: boolean; isLastRow?: boolean; isUpAvailable?: boolean }) => {
        return (
            <div style={{
                padding: 15,
            }}>
                {
                    isUpAvailable && !isNull
                        ? <LinkWrapperComponent onClick={() => getUp()}>
                            <img style={{ paddingBottom: 5 }} src={ArrowUpIcon} alt="arrow up icon" />
                        </LinkWrapperComponent>
                        : null
                }
                <div style={{
                    minWidth: '150px',
                    maxWidth: '250px',
                    textAlign: 'center',
                    backgroundColor: TemplateConfig.greyColorOpacity,
                    borderRadius: TemplateConfig.borderRadius,
                    padding: TemplateConfig.miniPadding,
                    margin: '0 auto'
                }}>
                    {
                        isNull
                            ? <TextComponent type='small'>
                                Свободная ячейка
                            </TextComponent>
                            : <div>
                                <TextComponent type='default'>
                                    {username ?? 'Не указано'}
                                </TextComponent>
                                <RetirementComponent size={5} />
                                <TextComponent type='small'>
                                    {PriceFormatUtil(Number(personal_amount), false, 'PV')}
                                </TextComponent>
                                <RetirementComponent size={5} />
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <TextComponent type='small'>
                                        ЛК: {PriceFormatUtil(Number(left_amount), false, 'PV')}
                                    </TextComponent>
                                    <RetirementComponent size={10} />
                                    <TextComponent type='small'>
                                        ПК: {PriceFormatUtil(Number(right_amount), false, 'PV')}
                                    </TextComponent>
                                </div>
                            </div>
                    }
                </div>
                {
                    isLastRow && !isNull
                        ? <LinkWrapperComponent onClick={() => getDown(id!)}>
                            <img style={{ paddingTop: 5 }} src={ArrowDownIcon} alt="arrow down icon" />
                        </LinkWrapperComponent>
                        : null
                }
            </div>
        );
    }
    function getTreeItem(index: number) {
        if (!currentTreeData!.tree[index]) return treeItem({
            isNull: true,
            isLastRow: index > 2
        })
        return treeItem({
            id: currentTreeData!.tree[index]?.id,
            username: currentTreeData!.tree[index]?.username,
            left_amount: currentTreeData!.tree[index]?.left_amount,
            right_amount: currentTreeData!.tree[index]?.right_amount,
            personal_amount: currentTreeData!.tree[index]?.personal_amount,
            isLastRow: index > 2,
            isUpAvailable: index === 0 && treeHistory[0] !== currentTreeData!.tree[index]?.id
        })
    }

    // data
    const [currentTreeData, setCurrentTreeData] = useState(treeDataStore.treeData);
    const [treeHistory, setTreeHistory] = useState([customerDataStore.customerData?.customer.id]);
    function getUp() {
        const newTreeHistory = treeHistory;
        newTreeHistory.pop();
        if (newTreeHistory.length === 1) {
            setCurrentTreeData(treeDataStore.treeData);
        } else {
            loadItem(newTreeHistory[newTreeHistory.length - 1]!);
        }

        setTreeHistory(newTreeHistory);
    }
    function getDown(id: string) {
        const newTreeHistory = treeHistory;
        newTreeHistory.push(id);
        setTreeHistory(newTreeHistory);
        loadItem(id);
    }
    async function loadItem(id: string) {
        var result = await GetTreeData({ treeOwnerId: id });
        setCurrentTreeData(result);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Бинарное дерево">
            {
                !currentTreeData
                    ? null
                    : <TransformWrapper
                        initialScale={0.5}
                        minScale={0.5}
                        maxScale={5}
                        centerOnInit
                    >
                        <TransformComponent
                            wrapperStyle={{
                                width: '100%',
                                height: '300px',
                                border: `1px solid ${TemplateConfig.greyColor}`,
                                borderRadius: TemplateConfig.borderRadius
                            }}
                        >
                            <Tree label={getTreeItem(0)}>
                                <TreeNode label={getTreeItem(1)}>
                                    <TreeNode label={getTreeItem(3)} />
                                    <TreeNode label={getTreeItem(4)} />
                                </TreeNode>
                                <TreeNode label={getTreeItem(2)}>
                                    <TreeNode label={getTreeItem(5)} />
                                    <TreeNode label={getTreeItem(6)} />
                                </TreeNode>
                            </Tree>
                        </TransformComponent>
                    </TransformWrapper>
            }
        </StandartBottomSheetComponent>
    );
}

export default TreeComponent;