import RaisedButtonComponent from "./RaisedButtonComponent";
import RetirementComponent from "./RetirementComponent";
import TextComponent from "./TextComponent";
import StandartBottomSheetComponent from "./StandartBottomSheetComponent";

export default function ConfirmBottomSheetComponent({ open, onDismiss, onSubmit, description, title }: { open: any; onDismiss: any; onSubmit: any; description: string; title: string }) {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={title}>
            <div style={{ textAlign: 'center' }}>
                <TextComponent type='paragraph'>{description}</TextComponent>
            </div>
            <RetirementComponent />
            <RaisedButtonComponent
                onClick={() => onSubmit()}
                placeholder="Продолжить"
            />
        </StandartBottomSheetComponent>
    );
}