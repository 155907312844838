import { action, makeObservable, observable } from 'mobx';
import { Partners, PartnersResponse } from '../model/PartnersResponse';

class PartnersDataStore {
    partnersData: PartnersResponse | null = null;
    partnersCurrentPage: number = 1;
    partnersLoadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            partnersData: observable,
            partnersCurrentPage: observable,
            pushPartnersData: action,
            removePartnersData: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            loadDisable: action,
            partnersDataChange: action,
            replacePartnersData: action
        });
    }

    incrementCurrentPage() {
        return this.partnersCurrentPage = this.partnersCurrentPage + 1;
    }

    resetCurrentPage() {
        return this.partnersCurrentPage = 1;
    }

    loadDisable(value: boolean) {
        this.partnersLoadAviable = value;
    }

    replacePartnersData(partners: Partners[]) {
        this.partnersData = {
            partners: partners
        }
    }

    pushPartnersData(partners: Partners[]) {
        if (!this.partnersData) {
            this.partnersData = {
                partners: partners
            }
        } else {
            this.partnersData.partners = [...this.partnersData.partners, ...partners];
        }
    }

    partnersDataChange({ index, surname, username, address, telephone }: { index: number; surname: string; username: string; address: string; telephone: string }) {
        if (!this.partnersData) return;
        this.partnersData.partners[index].surname = surname;
        this.partnersData.partners[index].username = username;
        this.partnersData.partners[index].address = address;
        this.partnersData.partners[index].telephone = telephone;
    }

    removePartnersData() {
        this.partnersData = null;
    }
}

const partnersDataStore = new PartnersDataStore();
export default partnersDataStore;
