import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { PartnersResponse } from "../model/PartnersResponse";
import fundPartnersDataStore from "../store/FundPartnersDataStore";

export default async function GetFundPartnersService({ page }: { page: number }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "fundData",
        "accessToken": accessToken,
        "page": page.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    if ((result.data as PartnersResponse).partners.length === 0) {
        fundPartnersDataStore.updateLoadAviable(false);
        if (page !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    fundPartnersDataStore.push((result.data as PartnersResponse).partners);
    return result.data;
}