import { action, makeObservable, observable } from 'mobx';
import { TreeDataResponse } from '../model/TreeDataResponse';

class TreeDataStore {
    treeData: TreeDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            treeData: observable,
            saveTree: action,
            removeTree: action,
        });
    }

    saveTree(value: TreeDataResponse) {
        this.treeData = value;
    }

    removeTree() {
        this.treeData = null;
    }
}

const treeDataStore = new TreeDataStore();
export default treeDataStore;
