import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import partnersDataStore from "../store/PartnersDataStore";
export default async function UpdateDataService({ pointCustomerId, surname, username, address, telephone, index }: { pointCustomerId: string; index: number; surname: string; username: string; address: string, telephone: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "updateData",
        "accessToken": accessToken,
        "pointCustomerId": pointCustomerId,
        "surname": surname,
        "username": username,
        "address": address,
        "telephone": telephone
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    partnersDataStore.partnersDataChange({
        index: index,
        surname: surname,
        username: username,
        address: address,
        telephone: telephone
    });
    toast("Успешно обновлено", { type: "success" });
}