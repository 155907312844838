import { useNavigate } from "react-router-dom";
import LogoutService from "../../authenticate/service/LogoutService";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import { useState } from "react";
import LogoutIcon from '../../../core/assets/module/wrapper/logout.svg';
import ProfileIcon from '../../../core/assets/module/wrapper/profile.svg';
import MarketIcon from '../../../core/assets/module/wrapper/market.svg';
import PartnerIcon from '../../../core/assets/module/wrapper/partner.svg';
import BalanceIcon from '../../../core/assets/module/wrapper/balance.svg';
import AdminIcon from '../../../core/assets/module/wrapper/admin.svg';
import Icon from '../../../core/assets/module/shared/avo.png';
import SharedListComponent from "../../shared/ui/SharedListComponent";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import RetirementComponent from "./RetirementComponent";

const SharedMenuBottomSheetComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    const navigate = useNavigate();

    // logout
    const [logoutState, setLogoutState] = useState(false);
    function handleLogout() {
        setLogoutState(false);
        LogoutService();
        navigate(RouteNamespaces.authenticate);
    }

    let menuElements = [
        {
            label: RouteNamespaces.profileName,
            onClick: () => { navigate(RouteNamespaces.profile); onDismiss() },
            icon: ProfileIcon
        },
        {
            label: RouteNamespaces.marketName,
            onClick: () => { navigate(RouteNamespaces.market); onDismiss() },
            icon: MarketIcon
        },
        {
            label: RouteNamespaces.partnerName,
            onClick: () => { navigate(RouteNamespaces.partner); onDismiss() },
            icon: PartnerIcon
        },
        {
            label: RouteNamespaces.balanceName,
            onClick: () => { navigate(RouteNamespaces.balance); onDismiss() },
            icon: BalanceIcon
        },
    ];
    if (customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1') menuElements.push(
        {
            label: RouteNamespaces.adminName,
            onClick: () => { navigate(RouteNamespaces.admin); onDismiss() },
            icon: AdminIcon
        }
    );
    menuElements.push({
        label: RouteNamespaces.logoutName,
        onClick: () => setLogoutState(true),
        icon: LogoutIcon
    });

    return (
        <div>
            {/* <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Меню"> */}
            <Drawer
                zIndex={2}
                open={open}
                onClose={onDismiss}
                direction='left'
                className="sharedDrawer"
            >
                <div style={{
                    paddingTop: 40,
                    paddingLeft: 30
                }}>
                    <img src={Icon} alt="drawer icon" height={125} />
                    <RetirementComponent size={30} />
                    <SharedListComponent items={menuElements} />
                </div>
            </Drawer>
            {/* </StandartBottomSheetComponent> */}
            <ConfirmBottomSheetComponent
                open={logoutState}
                onDismiss={() => setLogoutState(false)}
                onSubmit={handleLogout}
                description="Чтобы выйти из системы нажмите 'Продолжить'."
                title={RouteNamespaces.logoutName}
            />
        </div>
    )
}

export default SharedMenuBottomSheetComponent;