import { action, makeObservable, observable } from 'mobx';
import { VariablesRespnonse } from '../model/VariablesResponse';

class VariablesStore {
    variables: VariablesRespnonse | null = null;

    constructor() {
        makeObservable(this, {
            variables: observable,
            saveVariables: action,
            removeVariables: action,
        });
    }

    saveVariables(value: VariablesRespnonse) {
        this.variables = value;
    }

    removeVariables() {
        this.variables = null;
    }
}

const variablesStore = new VariablesStore();
export default variablesStore;
