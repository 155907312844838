import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { VariablesRespnonse } from "../model/VariablesResponse";
import variablesStore from "../store/VariablesStore";
export default async function GetVariablesService() {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "data",
        "accessToken": accessToken
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // add result to store
    variablesStore.saveVariables(result.data as VariablesRespnonse);
    return result.data;
}