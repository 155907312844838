import partnerDataStore from "../../partner/store/PartnerDataStore";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import customerDataStore from "../store/CustomerDataStore";
import { ru } from 'date-fns/locale';
import { format } from "date-fns";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import DataComponent from "./DataComponent";
import DataService from "../service/DataService";
import PasswordComponent from "./PasswordComponent";

const ProfileComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;
    const [name, setName] = useState(false);
    function saveData(username: string, surname: string, address: string) {
        DataService({
            username: username,
            surname: surname,
            address: address
        })
    }

    // password
    const [passwordWindow, setPasswordWindow] = useState(false);

    return (
        <>
            <SharedCardItemComponent
                buttonText="Изменить"
                buttonAction={() => setName(true)}
            >
                {
                    [
                        { label: "Имя", value: customerDataStore!.customerData?.customer.username ?? 'Не указано' },
                        { label: "Фамилия", value: customerDataStore!.customerData?.customer.surname ?? 'Не указано' },
                        { label: "Номер телефона", value: TelephoneFormatUtil(customerDataStore.customerData!.customer.telephone) },
                        { label: "Адрес", value: customerDataStore!.customerData?.customer.address ?? 'Не указано' },
                        { label: "Дата регистрации", value: format(Date.parse(partner.created_at), 'dd MMMM yyyy', { locale: ru }) },
                        { label: "Пароль", value: "Изменить", onClick: () => setPasswordWindow(true), clickable: true }
                    ]
                }
            </SharedCardItemComponent>
            <DataComponent open={name} onDismiss={() => setName(false)} onSubmit={saveData} />
            <PasswordComponent open={passwordWindow} onDismiss={() => setPasswordWindow(false)} />
        </>
    );
}

export default observer(ProfileComponent);