import { styled } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '../../../core/assets/module/wrapper/menu.svg';
import LinkWrapperComponent from '../../shared/ui/LinkWrapperComponent';
import RetirementComponent from './RetirementComponent';
import TextComponent from './TextComponent';
import SharedMenuBottomSheetComponent from './SharedMenuBottomSheetComponent';
import Icon from '../../../core/assets/module/shared/avo2.png';

const SharedHeaderComponent = ({ title }: { title: string }) => {
    // menu
    const [menuState, setMenuState] = useState(false);

    // style
    const HeaderWrapper = styled('div')({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    });

    return (
        <div>
            <HeaderWrapper>
                <LinkWrapperComponent onClick={() => setMenuState(true)}>
                    <img src={MenuIcon} alt="menu icon" />
                </LinkWrapperComponent>
                <img height={18} src={Icon} alt="lototype icon" />
            </HeaderWrapper>
            <RetirementComponent size={48} />
            <div>
                <TextComponent type='extraLarge'>
                    {title}
                </TextComponent>
            </div>
            <RetirementComponent size={30} />
            <SharedMenuBottomSheetComponent open={menuState} onDismiss={() => setMenuState(false)} />
        </div>
    );
}

export default SharedHeaderComponent;