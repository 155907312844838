import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ReferralDataResponse } from "../model/ReferralDataResponse";
import referralDataStore from "../store/ReferralDataStore";
export default async function GetReferralsService({ page }: { page: number }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "partner",
        "process": "referralData",
        "accessToken": accessToken,
        "page": page.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    if ((result.data as ReferralDataResponse).referrals.length === 0) {
        referralDataStore.loadDisable(false);
        if (page !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    referralDataStore.pushReferralData((result.data as ReferralDataResponse).referrals);
    return result.data;
}