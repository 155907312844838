import { action, makeObservable, observable } from 'mobx';
import { Orders, OrdersResponse } from '../model/OrdersResponse';

class OrdersDataStore {
    ordersData: OrdersResponse | null = null;
    ordersCurrentPage: number = 1;
    ordersLoadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            ordersData: observable,
            ordersCurrentPage: observable,
            pushOrdersData: action,
            removeOrdersData: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            loadDisable: action,
            deliveredStatusChange: action,
            replaceOrdersData: action
        });
    }

    incrementCurrentPage() {
        return this.ordersCurrentPage = this.ordersCurrentPage + 1;
    }

    resetCurrentPage() {
        return this.ordersCurrentPage = 1;
    }

    loadDisable(value: boolean) {
        this.ordersLoadAviable = value;
    }

    replaceOrdersData(orders: Orders[]) {
        this.ordersData = {
            orders: orders
        }
    }

    pushOrdersData(orders: Orders[]) {
        if (!this.ordersData) {
            this.ordersData = {
                orders: orders
            }
        } else {
            this.ordersData.orders = [...this.ordersData.orders, ...orders];
        }
    }

    deliveredStatusChange(index: number, status: string) {
        if (!this.ordersData) return;
        this.ordersData.orders[index].delivered = status;
    }

    removeOrdersData() {
        this.ordersData = null;
    }
}

const ordersDataStore = new OrdersDataStore();
export default ordersDataStore;
