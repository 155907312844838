import { useRef, useState } from "react";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import EyeIcon from "../../../core/assets/module/input/eye.svg";
import EyeShowIcon from "../../../core/assets/module/input/eye-show.svg";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import PasswordService from "../service/PasswordService";
import { toast } from "react-toastify";

const PasswordComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any; }) => {
    const password = useRef('');
    const onPasswordChange = (v: string) => { password.current = v; };
    const [obscure, setObscure] = useState(true);
    async function savePassword() {
        if (password.current.length < 3) {
            toast("Введите сложный пароль", { type: "error" });
            return;
        }

        await PasswordService(password.current);
        onPasswordChange('');
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Изменить'>
            <InputComponent
                initText={password.current}
                placeholder="Новый пароль"
                handleTextFieldValueChange={onPasswordChange}
                maxLength={100}
                password={obscure}
                endAdornment={<LinkWrapperComponent onClick={() => setObscure(!obscure)}>
                    <img src={obscure ? EyeIcon : EyeShowIcon} alt="obscure icon" />
                </LinkWrapperComponent>}
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder="Сохранить"
                onClick={savePassword}
            />
        </StandartBottomSheetComponent>
    );
}

export default PasswordComponent;