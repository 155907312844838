import { styled } from "@mui/material";
import TextComponent from "./TextComponent";

const PageNotFoundComponent = () => {
  const ErrorBlock = styled('div')({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  });

  return (
    <ErrorBlock>
      <TextComponent type='default'>
        404: Page not found
      </TextComponent>
    </ErrorBlock>
  );
}

export default PageNotFoundComponent;