import { action, makeObservable, observable } from 'mobx';
import { CustomerDataResponse } from '../model/CustomerDataResponse';

class CustomerDataStore {
    customerData: CustomerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            customerData: observable,
            saveCustomer: action,
            removeCustomer: action,
            updateData: action
        });
    }

    saveCustomer(value: CustomerDataResponse) {
        this.customerData = value;
    }

    updateData({ username, surname, address }: { username?: string; surname?: string; address?: string }) {
        if (!this.customerData) return;
        if (username) this.customerData.customer.username = username;
        if (surname) this.customerData.customer.surname = surname;
        if (address) this.customerData.customer.address = address;
    }

    removeCustomer() {
        this.customerData = null;
    }
}

const customerDataStore = new CustomerDataStore();
export default customerDataStore;
