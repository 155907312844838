import { useEffect, useState } from "react";
import GlobalAuthVerifyService from "../module/authenticate/service/GlobalAuthVerifyService";
import LoadingModalComponent from "../module/shared/ui/LoadingModalComponent";
import RouteComponent from "./route/RouteComponent";

export default function App() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function initialize() {
      await GlobalAuthVerifyService();
      setInitialized(true);
    }

    initialize();
  }, []);

  if (!initialized) return <LoadingModalComponent />
  return (
    <RouteComponent />
  );
}