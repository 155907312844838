import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { SharedCardItemModel } from "../../shared/model/SharedCardItemModel";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { observer } from "mobx-react-lite";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RankNameUtil from "../../../core/utils/RankNameUtil";
import fundPartnersDataStore from "../store/FundPartnersDataStore";
import GetFundPartnersService from "../service/GetFundPartnersService";

const FundPartnersComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!fundPartnersDataStore.data && open && fundPartnersDataStore.loadAviable) { await GetFundPartnersService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // partners 
    const Partners = () => {
        if (!fundPartnersDataStore.data) return null;

        const partnerItems: SharedCardItemModel[] = [];
        fundPartnersDataStore.data.partners.map((field, index) => (
            partnerItems.push({
                label: TelephoneFormatUtil(field.telephone),
                text: `ЛК: ${PriceFormatUtil(Number(field.left_amount), false, 'PV')} ПК: ${PriceFormatUtil(Number(field.right_amount), false, 'PV')}, ${RankNameUtil(field.left_total_amount, field.right_total_amount)}`,
                subtitle: `${field.username ?? 'Не указано'}, ${field.surname ?? 'Не указано'}, ${field.address ?? 'Не указано'}`,
                value: "",
                last: index === (fundPartnersDataStore.data?.partners.length! - 1),
            })
        ))
        return (
            <SharedCardItemComponent>
                {partnerItems}
            </SharedCardItemComponent>
        );
    }
    // function
    async function loadMore() {
        var newPage = fundPartnersDataStore.incrementCurrentPage();
        await GetFundPartnersService({ page: newPage });
        setReload(!reload);
    }

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Участники фонда'>
                <Partners />
                {
                    fundPartnersDataStore.loadAviable
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Загрузить еще"
                            />
                        </div>
                        : null
                }
            </StandartBottomSheetComponent>
        </>
    );
}

export default observer(FundPartnersComponent);