import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function RecoveryService({ telephone, code, password }: { telephone: string; code?: string; password?: string }) {
    // create params
    const params = {
        "module": "account",
        "process": "recovery",
        "telephone": telephone,
        "code": code,
        "password": password
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: !code ? "Аккаунт с указанным номером телефона не найден" : "Неверный код восстановления"
        }
    );
    if (!result || !result.status) return false;

    if (!code) {
        toast("Код восстановления успешно отправлен", { type: "success" });
        return true;
    }

    toast("Пароль успешно изменен", { type: "success" });
    return true;
}