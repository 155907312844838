import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateConfig from "../../../core/config/TemplateConfig";
import InputPriceFormatUtil from "../../../core/utils/InputPriceFormatUtil";
import TelephoneFormateUtil from "../../../core/utils/TelephoneFormateUtil";

const InputComponent = ({ placeholder, rows = '1', maxLength = 50, endAdornment, startAdornment, handleTextFieldValueChange, filter = ``, mobileFilter = false, initText, multiline, disabled, digitFilter = false, priceFilter = false, ref, password = false }: { placeholder: string; rows?: string; maxLength?: number; endAdornment?: any; startAdornment?: any; handleTextFieldValueChange?: any; filter?: any; mobileFilter?: boolean; initText?: string | null; multiline?: boolean; disabled?: boolean; digitFilter?: boolean, priceFilter?: boolean; ref?: any; password?: boolean }) => {
    useEffect(() => {
        if (initText) setText(initText);
    }, [initText]);

    const [text, setText] = useState('');
    function onTextChange(value: string) {
        const formattedValue = priceFilter ? InputPriceFormatUtil(value) : mobileFilter ? TelephoneFormateUtil(value) : digitFilter ? value.replace(/[^0-9]/g, '') : value.replace(filter, '');
        setText(formattedValue);
        handleTextFieldValueChange(formattedValue);
    }

    return (
        <TextField
            ref={ref}
            type={password ? 'password' : undefined}
            placeholder={placeholder}
            onChange={(event) => !event ? null : onTextChange(event.target.value)}
            value={text}
            fullWidth
            multiline={multiline}
            rows={rows}
            variant="standard"
            style={{ fontWeight: TemplateConfig.regularText }}
            InputProps={{
                disableUnderline: true,
                readOnly: disabled,
                sx: {
                    borderRadius: 8,
                    backgroundColor: TemplateConfig.greyColor,
                    color: TemplateConfig.blackColor,
                    padding: '8px 16px'
                },
                startAdornment: !startAdornment ? null : <span style={{ paddingRight: 10, display: 'flex', justifyItems: 'center' }}>{startAdornment}</span>,
                endAdornment: !endAdornment ? null : <span style={{ paddingLeft: 10 }}>{endAdornment}</span>,
            }}
            inputProps={{
                maxLength: maxLength,
                style: {
                    // padding: '12.5px 16px',
                    fontWeight: TemplateConfig.mediumText,
                    fontSize: TemplateConfig.mediumFontSize
                },
            }}
        />
    );
}

export default InputComponent;