import variablesStore from "../../admin/store/VariablesStore";
import historyDataStore from "../../balance/store/HistoryDataStore";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import referralDataStore from "../../partner/store/ReferralDataStore";
import customerDataStore from "../store/CustomerDataStore";
import LogoutRequestService from "./LogoutRequestService";

export default function LogoutService(request: boolean = true) {
    if (request) LogoutRequestService();
    localStorage.removeItem("accessToken");

    // partner
    customerDataStore.removeCustomer();
    partnerDataStore.removePartner();
    historyDataStore.loadDisable(true);
    historyDataStore.resetCurrentPage();
    historyDataStore.removeHistory();
    referralDataStore.loadDisable(true);
    referralDataStore.resetCurrentPage();
    referralDataStore.removeReferralData();

    // admin
    variablesStore.removeVariables();
}