import { styled } from '@mui/material';
import TextComponent from './TextComponent';
import RetirementComponent from './RetirementComponent';
// import CloseIcon from '../../../core/assets/module/shared/close.svg';
// import TickIcon from '../../../core/assets/module/shared/tick.svg';
// import LinkWrapperComponent from './LinkWrapperComponent';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useRef, useEffect } from 'react';

const StandartBottomSheetComponent = ({
    open,
    onDismiss,
    onSubmit,
    children,
    title,
    description,
    zIndex = 2
}: {
    open: boolean;
    onDismiss: any;
    onSubmit?: any;
    children: any;
    title: string;
    description?: string;
    zIndex?: number
}) => {
    // focus
    const dummyElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open && dummyElementRef.current) {
            dummyElementRef.current.focus();
        }
    }, [open]);

    // style
    const SheetHeaderStyled = styled('div')({
        // display: 'grid',
        // gridTemplateColumns: '30px 1fr 30px',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 10
    });

    // widget
    return (
        <BottomSheet
            scrollLocking={!/iPhone|iPad|iPod/i.test(navigator.userAgent)}
            autoFocus={false}
            open={open}
            style={{ zIndex: zIndex }}
            onDismiss={onDismiss}
            header={
                <SheetHeaderStyled>
                    {/* <LinkWrapperComponent deleteMarginBottom onClick={() => onDismiss(false)}>
                        <img style={{ textAlign: 'left' }} src={CloseIcon} alt="close icon" />
                    </LinkWrapperComponent> */}
                    <div style={{ textAlign: 'center' }}>
                        <TextComponent type='boldMedium'>
                            {title}
                        </TextComponent>
                    </div>
                    {/* {
                        onSubmit != null
                            ? <LinkWrapperComponent deleteMarginBottom onClick={onSubmit}>
                                <img style={{ textAlign: 'right' }} src={TickIcon} alt="tick icon" />
                            </LinkWrapperComponent>
                            : null
                    } */}
                </SheetHeaderStyled>
            }
        >
            <div>
                <div tabIndex={0} ref={dummyElementRef} style={{ width: 0, height: 0, overflow: 'hidden' }} />
                {
                    description
                        ? (<div style={{ textAlign: 'center' }}>
                            <TextComponent type='small'>
                                {description}
                            </TextComponent>
                            <RetirementComponent />
                        </div>)
                        : null
                }
                {children}
            </div>
        </BottomSheet>
    );
}

export default StandartBottomSheetComponent;