import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "./LinkWrapperComponent";
import TextComponent from "./TextComponent";

const TextButtonComponent = ({ placeholder, onClick, type = "default", color = TemplateConfig.greenColor, weight }: { placeholder: string; onClick: any; type?: string; color?: string; weight?: number }) => {
    return (
        <LinkWrapperComponent onClick={onClick}>
            <TextComponent type={type} color={color} weight={weight}>
                <span style={{ textDecoration: 'underline', textDecorationThickness: 0.1, textUnderlineOffset: 2 }}>{placeholder}</span>
            </TextComponent>
        </LinkWrapperComponent>
    )
}

export default TextButtonComponent;