import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { KitsResponse } from "../../market/model/KitsResponse";
import kitsDataStore from "../../market/store/KitsDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { RanksResponse } from "../model/RanksResponse";
import rankDataStore from "../store/RankStore";

export default async function GlobalDataService() {
    if (rankDataStore.rankData && kitsDataStore.kitsData) return;

    // request
    const params = {
        "module": "globalData"
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    rankDataStore.saveData(result.data as RanksResponse);
    kitsDataStore.saveData(result.data as KitsResponse);
}