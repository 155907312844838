import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { OrdersResponse } from "../model/OrdersResponse";
import ordersDataStore from "../store/OrdersDataStore";
export default async function GetOrdersService({ page, telephone, reset = false }: { page: number; telephone?: string; reset?: boolean }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "orders",
        "accessToken": accessToken,
        "page": page.toString(),
        "telephone": telephone ?? ''
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    if (!telephone || telephone.length === 0) {
        if ((result.data as OrdersResponse).orders.length === 0) {
            ordersDataStore.loadDisable(false);
            if (page !== 1) toast("Все данные загружены", { type: "success" });
            return result.data;
        }
        if (reset) ordersDataStore.replaceOrdersData((result.data as OrdersResponse).orders);
        else ordersDataStore.pushOrdersData((result.data as OrdersResponse).orders);
        return result.data;
    }

    if ((result.data as OrdersResponse).orders.length === 0) {
        toast("Заказы не найдены", { type: "error" });
        return result.data;
    }
    ordersDataStore.loadDisable(false);
    ordersDataStore.replaceOrdersData((result.data as OrdersResponse).orders);
    toast("Успешно загружено", { type: "success" });
}