import { styled } from "@mui/material";
import { ReactNode } from "react";
import RetirementComponent from "./RetirementComponent";

const SharedComponentWrapper = ({ children }: { children: ReactNode }) => {
    const BodyWrapper = styled('div')({
        height: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    });

    return (
        <BodyWrapper>
            {children}
            <RetirementComponent />
        </BodyWrapper>
    );
}

export default SharedComponentWrapper;