import { action, makeObservable, observable } from 'mobx';
import { Referral, ReferralDataResponse } from '../model/ReferralDataResponse';

class ReferralDataStore {
    referralData: ReferralDataResponse | null = null;
    referralCurrentPage: number = 1;
    referralLoadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            referralData: observable,
            referralCurrentPage: observable,
            pushReferralData: action,
            removeReferralData: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            loadDisable: action
        });
    }

    incrementCurrentPage() {
        return this.referralCurrentPage = this.referralCurrentPage + 1;
    }

    resetCurrentPage() {
        return this.referralCurrentPage = 1;
    }

    loadDisable(value: boolean) {
        this.referralLoadAviable = value;
    }

    pushReferralData(referrals: Referral[]) {
        if (!this.referralData) {
            this.referralData = {
                referrals: referrals
            }
        } else {
            this.referralData.referrals = [...this.referralData.referrals, ...referrals];
        }
    }

    removeReferralData() {
        this.referralData = null;
    }
}

const referralDataStore = new ReferralDataStore();
export default referralDataStore;
