import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import AccessTokenVerifyService from "./AccessTokenVerifyService";

export default async function PasswordService(password: string) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "updatePassword",
        "password": password,
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.status) return;

    // store
    toast("Успешно обновлено", { type: "success" });
}