import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import customerDataStore from "../store/CustomerDataStore";
import AccessTokenVerifyService from "./AccessTokenVerifyService";

export default async function DataService({ username, surname, address }: { username?: string; surname?: string; address?: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "updateData",
        "username": username ?? '',
        "surname": surname ?? '',
        "address": address ?? '',
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );
    if (!result || !result.status) return;

    // store
    customerDataStore.updateData({ username: username, surname: surname, address: address });
    toast("Успешно обновлено", { type: "success" });
}