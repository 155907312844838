import SharedHeaderComponent from "./SharedHeaderComponent";
import SharedWrapperComponent from "./SharedWrapperComponent";

const BackgroundComponent = ({ children, title }: { children: any; title: string }) => {
    return (
        <SharedWrapperComponent>
            <div style={{ height: '100% - 21px' }}>
                <SharedHeaderComponent title={title} />
                {children}
            </div>
        </SharedWrapperComponent>
    );
}

export default BackgroundComponent;