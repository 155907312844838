import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import { Partners } from "../../admin/model/PartnersResponse";
import InputComponent from "../../shared/ui/InputComponent";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import AddressComponent from "./AddressComponent";

const DataComponent = ({ open, onDismiss, onSubmit, init, admin = false }: { open: boolean; onDismiss: any; onSubmit: any; init?: Partners | null; admin?: boolean }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (open && init && admin) {
            telephoneField.current = TelephoneFormatUtil(init.telephone);
            usernameFieldRef.current = init.username ?? '';
            surnameFieldRef.current = init.surname ?? '';
            setAddress(init.address ?? '');
            setReload(!reload);
        }
        // eslint-disable-next-line
    }, [open, init, admin]);

    // telephone
    const telephoneField = useRef('');
    const onTelephoneFieldRefChange = (newValue: string) => { telephoneField.current = newValue; };

    // username
    const usernameFieldRef = useRef('');
    const onUsernameFieldRefChange = (newValue: string) => { usernameFieldRef.current = newValue; };

    // surname
    const surnameFieldRef = useRef('');
    const onSurnameFieldRefChange = (newValue: string) => { surnameFieldRef.current = newValue; };

    // address
    const [address, setAddress] = useState('');
    const [addressWindow, setAddressWindow] = useState(false);

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Изменить'>
                {
                    admin && <>
                        <InputComponent
                            initText={telephoneField.current}
                            placeholder="Новый телефон"
                            handleTextFieldValueChange={onTelephoneFieldRefChange}
                            mobileFilter
                        />
                        <RetirementComponent size={10} />
                    </>
                }
                <InputComponent
                    initText={usernameFieldRef.current}
                    placeholder="Новое имя"
                    handleTextFieldValueChange={onUsernameFieldRefChange}
                    filter={/[^A-Za-z]/g}
                    maxLength={100}
                />
                <RetirementComponent size={10} />
                <InputComponent
                    initText={surnameFieldRef.current}
                    placeholder="Новая фамилия"
                    handleTextFieldValueChange={onSurnameFieldRefChange}
                    filter={/[^A-Za-z]/g}
                    maxLength={100}
                />
                <RetirementComponent size={10} />
                <LinkWrapperComponent onClick={() => setAddressWindow(true)}>
                    <InputComponent
                        disabled
                        placeholder={'Выберите город'}
                        initText={address.length > 0 ? address : undefined}
                    />
                </LinkWrapperComponent>
                <RetirementComponent size={10} />
                <RaisedButtonComponent
                    placeholder="Продолжить"
                    onClick={() => {
                        if (!usernameFieldRef.current || usernameFieldRef.current.length === 0 || !surnameFieldRef.current || surnameFieldRef.current.length === 0 || address.length === 0) {
                            toast("Заполните все поля", { type: "error" });
                            return;
                        }
                        if (admin && (!telephoneField.current || telephoneField.current.length < 10)) {
                            toast("Заполните все поля", { type: "error" });
                            return;
                        }
                        if (admin) onSubmit(usernameFieldRef.current, surnameFieldRef.current, address, telephoneField.current.replace(/[^0-9]/g, ''));
                        else onSubmit(usernameFieldRef.current, surnameFieldRef.current, address);
                        setAddress('');
                        onDismiss();
                    }}
                />
            </StandartBottomSheetComponent>
            <AddressComponent open={addressWindow} onDismiss={() => setAddressWindow(false)} onSubmit={(value: string) => { setAddress(value); setAddressWindow(false) }} />
        </>
    );
}

export default DataComponent;