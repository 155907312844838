export default function InputPriceFormatUtil(value: string) {
    const cleaned = value.replace(/[^\d.]/g, '');
    const parts = cleaned.split('.');

    let formattedMoney = '';

    if (parts[0]) {
        formattedMoney = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    if (parts[1]) {
        formattedMoney += `.${parts[1].slice(0, 2)}`;
    }

    return formattedMoney ? `${formattedMoney}` : '';
}