import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import treeDataStore from "../store/TreeDataStore";

export default async function GetTreeData({ init = false, treeOwnerId }: { init?: boolean; treeOwnerId?: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "partner",
        "process": "treeData",
        "accessToken": accessToken,
        "treeOwnerId": treeOwnerId ?? ''
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // add result to store
    if (init) {
        treeDataStore.saveTree(result.data);
    }
    return result.data;
}