import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import FundService from "../service/FundService";

const FundComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // amount
    const amountFieldRef = useRef('');
    const onAmountFieldRefChange = (newValue: string) => { amountFieldRef.current = newValue };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Фондовый бонус'>
            <InputComponent
                placeholder="Сумма фонда"
                handleTextFieldValueChange={onAmountFieldRefChange}
                filter={/[^0-9]/g}
                maxLength={11}
                priceFilter
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder="Продолжить"
                onClick={() => {
                    if (amountFieldRef.current.length < 1) {
                        toast("Введите корректную сумму", { type: "error" });
                        return;
                    }

                    FundService({ amount: Number(amountFieldRef.current.replace(/\s/g, '')) });
                    onDismiss();
                }}
            />
        </StandartBottomSheetComponent>
    );
}

export default FundComponent;