import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import { useState } from "react";
import HistoryComponent from "./HistoryComponent";
import TransferComponent from "./TransferComponent";
import { observer } from "mobx-react-lite";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import CashbackComponent from "./CashbackComponent";

const BalanceComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // history
    const [history, setHistory] = useState(false);

    // transfer
    const [transfer, setTransfer] = useState(false);

    // cashback
    const [cashback, setCashback] = useState(false);

    return (
        <>
            <SharedCardItemComponent
                buttonText="Перевод"
                buttonAction={
                    () => setTransfer(true)
                }
            >
                {
                    [
                        { label: "Основной счёт", value: PriceFormatUtil(Number(partner.balance), true) },
                        {
                            label: "История", value: "Посмотреть", clickable: true, onClick: () => setHistory(true)
                        },
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent />
            <SharedCardItemComponent
                buttonText="Перевод"
                buttonAction={
                    () => setCashback(true)
                }
            >
                {
                    [
                        { label: "Cashback счет", value: PriceFormatUtil(Number(partner.cashback_amount), true) }
                    ]
                }
            </SharedCardItemComponent>
            <HistoryComponent open={history} onDismiss={() => setHistory(false)} />
            <TransferComponent open={transfer} onDismiss={() => setTransfer(false)} />
            <CashbackComponent open={cashback} onDismiss={() => setCashback(false)} />
        </>
    );
}

export default observer(BalanceComponent);