import { action, makeObservable, observable } from 'mobx';
import { Partners, PartnersResponse } from '../model/PartnersResponse';

class FundPartnersDataStore {
    data: PartnersResponse | null = null;
    currentPage: number = 1;
    loadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            data: observable,
            currentPage: observable,
            push: action,
            remove: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            updateLoadAviable: action
        });
    }

    incrementCurrentPage() { return this.currentPage = this.currentPage + 1; }

    resetCurrentPage() { return this.currentPage = 1; }

    updateLoadAviable(value: boolean) { this.loadAviable = value; }

    push(partners: Partners[]) {
        if (!this.data) this.data = {
            partners: partners
        }
        else this.data.partners = [...this.data.partners, ...partners];
    }

    remove() { this.data = null; }
}

const fundPartnersDataStore = new FundPartnersDataStore();
export default fundPartnersDataStore;
