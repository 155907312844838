import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const SearchComponent = ({ open, onDismiss, onSubmit }: { open: boolean; onDismiss: any; onSubmit: any }) => {
    // telephone
    const telephoneFieldRef = useRef('');
    const onTelephoneFieldRefChange = (newValue: string) => { telephoneFieldRef.current = newValue; };


    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Поиск">
            <InputComponent
                placeholder="Введите номер телефона"
                handleTextFieldValueChange={onTelephoneFieldRefChange}
                mobileFilter
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder="Продолжить"
                onClick={() => {
                    if (!telephoneFieldRef.current || telephoneFieldRef.current.length < 10) {
                        toast("Заполните поле корректно", { type: "error" });
                        return;
                    }
                    onSubmit(telephoneFieldRef.current);
                    onDismiss();
                }}
            />
        </StandartBottomSheetComponent>
    );
}
export default SearchComponent;