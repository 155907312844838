import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { SharedCardItemModel } from "../../shared/model/SharedCardItemModel";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { observer } from "mobx-react-lite";
import TelephoneFormatUtil from "../../../core/utils/TelephoneFormateUtil";
import ordersDataStore from "../store/OrdersDataStore";
import GetOrdersService from "../service/GetOrdersService";
import { Switch } from "@mui/material";
import DeliveryService from "../service/DeliveryService";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import SearchComponent from "./SearchComponent";

const OrdersComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!ordersDataStore.ordersData && open && ordersDataStore.ordersLoadAviable) { await GetOrdersService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // search
    const [searchWindow, setSearchWindow] = useState(false);

    // orders 
    const Orders = () => {
        if (!ordersDataStore.ordersData) return null;

        const orderItems: SharedCardItemModel[] = [];
        ordersDataStore.ordersData.orders.map((field, index) => (
            orderItems.push({
                label: TelephoneFormatUtil(field.telephone),
                subtitle: `${field.kit}, ${field.username ?? 'Не указано'}, ${field.address ?? 'Не указано'}`,
                value: "",
                valueComponent: <Switch
                    checked={Number(field.delivered) === 1}
                    onChange={() => {
                        async function change() {
                            await DeliveryService({
                                orderId: field.id,
                                status: Number(field.delivered) === 1 ? '0' : '1',
                                index: index
                            });
                            setReload(!reload);
                        }
                        change();
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />,
                last: index === (ordersDataStore.ordersData?.orders.length! - 1),
            })
        ))
        return (
            <SharedCardItemComponent>
                {orderItems}
            </SharedCardItemComponent>
        );
    }
    async function loadMore() {
        var newPage = ordersDataStore.incrementCurrentPage();
        await GetOrdersService({ page: newPage });
        setReload(!reload);
    }
    async function reset() {
        ordersDataStore.loadDisable(true);
        ordersDataStore.resetCurrentPage();
        await GetOrdersService({ page: 1, reset: true });
        setReload(!reload);
    }
    async function search(telephone: string) {
        ordersDataStore.resetCurrentPage();
        await GetOrdersService({ page: 1, telephone: telephone.replace(/\D/g, '') });
        setReload(!reload);
    }

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Заказы'>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <TextButtonComponent
                        placeholder="Поиск"
                        onClick={() => setSearchWindow(true)}
                    />
                    <RetirementComponent size={10} />
                    <TextButtonComponent
                        placeholder="Сбросить"
                        onClick={reset}
                    />
                </div>
                <RetirementComponent size={20} />
                <Orders />
                {
                    ordersDataStore.ordersLoadAviable
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Загрузить еще"
                            />
                        </div>
                        : null
                }
                <style>
                    {
                        `
                    .Mui-checked {
                        color: ${TemplateConfig.greenColor} !important
                    }
                    .MuiSwitch-track {
                        background-color: ${TemplateConfig.greenColor} !important
                    }
                    `
                    }
                </style>
            </StandartBottomSheetComponent>
            <SearchComponent open={searchWindow} onDismiss={() => setSearchWindow(false)} onSubmit={search} />
        </>
    );
}

export default observer(OrdersComponent);