import { Card } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RetirementComponent from "./RetirementComponent";
import TextButtonComponent from "./TextButtonComponent";
import TextComponent from "./TextComponent";
import { SharedCardItemModel } from "../model/SharedCardItemModel";
import RaisedButtonComponent from "./RaisedButtonComponent";

const SharedCardItemComponent = ({ children, largeBorder = false, background = true, buttonText, buttonAction }: { children: SharedCardItemModel[]; largeBorder?: boolean; background?: boolean; buttonText?: string; buttonAction?: any }) => {
    if (children.length === 0) return null;
    return (
        <Card
            variant="outlined"
            style={{
                borderRadius: largeBorder ? TemplateConfig.borderRadius : TemplateConfig.miniBorderRadius,
                borderColor: background ? TemplateConfig.yellowColor : TemplateConfig.blackColor,
                padding: TemplateConfig.defaultPadding,
                backgroundColor: background ? TemplateConfig.yellowColor : 'none',
            }}
        >
            {
                children.map((field, index) => (
                    <div key={index}>
                        {
                            field.hide
                                ? null
                                : (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {field.icon}
                                            <div style={{ wordBreak: 'break-word' }}>
                                                <div style={{ paddingRight: 5 }}>
                                                    <TextComponent
                                                        type="boldMedium"
                                                        multiline
                                                    >
                                                        {field.label}
                                                    </TextComponent>
                                                </div>
                                                {field.subtitle ? <TextComponent
                                                    type="medium"
                                                >
                                                    {field.subtitle}
                                                </TextComponent> : null}
                                                {field.text ? <TextComponent
                                                    type="medium"
                                                >
                                                    {field.text}
                                                </TextComponent> : null}
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {

                                                field.clickable
                                                    ? (
                                                        <TextButtonComponent type="medium" placeholder={field.value} onClick={field.onClick} />
                                                    )
                                                    : field.valueComponent
                                                    ?? (
                                                        <TextComponent
                                                            type="medium"
                                                            multiline
                                                        >
                                                            {field.value}
                                                        </TextComponent>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                        }
                        {(!field.last && !field.hide) && <RetirementComponent size={10} />}
                    </div>
                ))
            }
            {
                buttonText && buttonAction
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            placeholder={buttonText}
                            onClick={buttonAction}
                        />
                    </div>
                    : null
            }
        </Card>
    );
}

export default SharedCardItemComponent;