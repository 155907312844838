import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function TransferService({ amount, telephone }: { amount: number; telephone: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "balance",
        "process": "transfer",
        "accessToken": accessToken,
        "amount": amount.toString(),
        "recipientTelephone": telephone
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.thisProductBackendMainPath2Url,
            params: params,
            errorText: customerDataStore.customerData?.customer.admin_permission.toString() === '0' ? 'Перевод возможен только на номер администратора' : 'Номер не найден'
        }
    );

    // check result
    if (!result || !result.data) return;

    // open link
    if (result.status === 'succes') {
        partnerDataStore.reduceBalance(Number(amount));
        toast("Успешно переведено", { type: "success" });
    }
}