import { useRef } from "react";
import { toast } from "react-toastify";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import CashbackService from "../service/CashbackService";

const CashbackComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // telephone
    const telephoneFieldRef = useRef('');
    const onTelephoneFieldRefChange = (newValue: string) => { telephoneFieldRef.current = newValue; };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Перевод кэшбек бонуса'>
            <InputComponent
                placeholder="Номер телефона получателя"
                handleTextFieldValueChange={onTelephoneFieldRefChange}
                mobileFilter
            />
            <RetirementComponent size={10} />
            <RaisedButtonComponent
                placeholder="Продолжить"
                onClick={() => {
                    if (telephoneFieldRef.current.length < 15) {
                        toast("Введите корректный номер телефона", { type: "error" });
                        return;
                    }

                    if (!partnerDataStore.partnerData || Number(partnerDataStore.partnerData.partner.cashback_amount) <= 0) {
                        toast("Недостаточно баланса", { type: "error" });
                        return;
                    }

                    CashbackService({ telephone: telephoneFieldRef.current.replace(/\D/g, '') });
                    onDismiss();
                }}
            />
        </StandartBottomSheetComponent>
    );
}

export default CashbackComponent;