import { useState } from "react";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import PurchaseService from "../service/PurchaseService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import { toast } from "react-toastify";
import { Kit } from "../model/KitsResponse";
import kitsDataStore from "../store/KitsDataStore";
import customerDataStore from "../../authenticate/store/CustomerDataStore";

const MarketComponent = () => {
  // purchase
  const [purchaseState, setPurchaseState] = useState(false);
  const [productAmount, setProductAmount] = useState(0);
  const [productName, setProductName] = useState('');
  function initPurchase() {
    if (productAmount > Number(partnerDataStore.partnerData!.partner.balance))
      toast("Недостаточно средств", { type: "error" });
    else
      PurchaseService({ kitName: productName });
    setPurchaseState(false);
  }

  // widget
  const kitItem = (field: Kit, index: number) => {
    return (
      <div key={index}>
        <SharedCardItemComponent
          buttonText="Купить"
          buttonAction={
            () => {
              if (!customerDataStore.customerData?.customer.username || !customerDataStore.customerData?.customer.surname || !customerDataStore.customerData?.customer.address) {
                toast("Для покупки нужно заполнить все поля в профиле", { type: "error" });
                return;
              }

              setProductName(field.name);
              setProductAmount(Number(field.price));
              setPurchaseState(true);
            }
          }
        >
          {
            [
              { label: "Наименование", value: field.name },
              {
                label: field.product.toString() === '0'
                  ? 'Место в ПП'
                  : `Продукт${field.product.toString() === '32'
                    ? ' (либо БЭМ)' : ''}`,
                value: field.product.toString() === '0' ? '' : field.product
              },
              { label: "Объем", value: `${field.sv} PV` },
              { label: "Стоимость", value: PriceFormatUtil(Number(field.price)) },
            ]
          }
        </SharedCardItemComponent>
        {index !== (kitsDataStore.kitsData!.kits.length - 1) && <RetirementComponent />}
      </div>
    );
  }

  return (
    <>
      {
        !kitsDataStore.kitsData
          ? null
          : <div>
            {
              kitsDataStore.kitsData.kits.map((field, index) => (
                kitItem(field, index)
              ))
            }
          </div>
      }
      <ConfirmBottomSheetComponent
        open={purchaseState}
        onDismiss={() => setPurchaseState(false)}
        onSubmit={initPurchase}
        description={`Сумма спишется с вашего основного счета. Чтобы приобрести '${productName}' нажмите на 'Продолжить'.`}
        title="Купить"
      />
    </>
  );
}

export default MarketComponent;