export default class ApplicationConfig {
    // namespace
    static applicationName: string = 'Avocaddo';

    // link
    static applicationLink: string = 'https://partners.avoshop.kz';
    static thisProductBackendDirectory: string = `${ApplicationConfig.applicationLink}/request/api/v1`;
    static thisProductBackendMainPath2Url: string = `${ApplicationConfig.thisProductBackendDirectory}/main.php`;

    // city
    static city = ["Алматы", "Астана", "Шымкент", "Актау", "Актобе", "Шымкент", "Актобе", "Караганда", "Кокшетау", "Костанай", "Кызылорда", "Павлодар", "Петропавловск", "Семей", "Талдыкорган", "Тараз", "Туркестан", "Уральск", "Усть-Каменогорск"];
}