import { useRef, useState } from "react";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import FullIcon from '../../../core/assets/module/shared/avo.png';
import InputComponent from "../../shared/ui/InputComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import EyeIcon from "../../../core/assets/module/input/eye.svg";
import EyeShowIcon from "../../../core/assets/module/input/eye-show.svg";
import LinkWrapperComponent from "../../shared/ui/LinkWrapperComponent";
import { useNavigate } from "react-router-dom";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import { toast } from "react-toastify";
import RecoveryService from "../service/RecoveryService";

const RecoveryComponent = () => {
    // init
    const navigate = useNavigate();

    // fields
    const telephone = useRef('');
    const telephoneChange = (v: string) => { telephone.current = v };
    const code = useRef('');
    const codeChange = (v: string) => { code.current = v };
    const password = useRef('');
    const passwordChange = (v: string) => { password.current = v; };

    // fields state
    const [codeSended, setCodeSended] = useState(false);
    const [obscure, setObscure] = useState(true);

    // function
    async function execRecovery() {
        if (codeSended) {
            if (code.current.length !== 4) {
                toast("Неверный код", { type: "error" });
                return;
            }
            if (password.current.length < 3) {
                toast("Введите сложный пароль", { type: "error" });
                return;
            }

            const result = await RecoveryService({
                telephone: telephone.current.replace(/\D/g, ''),
                code: code.current,
                password: password.current
            });
            if (result) navigate(RouteNamespaces.authenticate);
            return;
        }

        if (telephone.current.length < 10) {
            toast("Введите корректный номер телефона", { type: "error" });
            return;
        }
        const result = await RecoveryService({ telephone: telephone.current.replace(/\D/g, '') });
        if (result) setCodeSended(true);
    }

    return (
        <div>
            <div style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
                <img src={FullIcon} alt="project icon" height={150} />
                <RetirementComponent size={30} />
                <InputComponent
                    placeholder="Номер телефона"
                    handleTextFieldValueChange={telephoneChange}
                    mobileFilter
                />
                {
                    codeSended && <div>
                        <RetirementComponent size={10} />
                        <InputComponent
                            placeholder="Код подтверждения"
                            handleTextFieldValueChange={codeChange}
                            filter={/[^0-9]/g}
                            maxLength={4}
                        />
                        <RetirementComponent size={10} />
                        <InputComponent
                            initText={password.current}
                            placeholder="Новый пароль"
                            handleTextFieldValueChange={passwordChange}
                            maxLength={100}
                            password={obscure}
                            endAdornment={<LinkWrapperComponent onClick={() => setObscure(!obscure)}>
                                <img src={obscure ? EyeIcon : EyeShowIcon} alt="obscure icon" />
                            </LinkWrapperComponent>}
                        />
                    </div>
                }
                <RetirementComponent size={10} />
                <RaisedButtonComponent
                    placeholder={codeSended ? "Сохранить" : "Восстановить"}
                    onClick={execRecovery}
                />
            </div>
        </div>
    );
}

export default RecoveryComponent;